:root {
  --font-family: "Manrope", sans-serif;
  --second-family: "Manrope", sans-serif;
  --third-family: "Manrope", sans-serif;
  --font3: "RedHatDisplay", sans-serif;
  --content-width: 100%;
  --container-offset: 90px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --main-color: #ffffff;
  --dark-blue: #1e3a71;
  --white: #fff;
  --red: #ff7070;
  --red-transform: #e02f2f;
  --blue: #034ab5;
  --gray: #d3d3d3;
  --light-gray: #9daec8;
  --icon: #121330;
  --green: #10D424;
  --grey-2: #9aaab8;
  --pink: #E44390;
}

/* stylelint-disable */
/* stylelint-disable */
/* stylelint-disable */
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable */
/* stylelint-disable */
/* stylelint-disable value-keyword-case */
@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/../fonts/Inter-Light.woff2") format("woff2");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/../fonts/Inter-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/../fonts/Inter-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/../fonts/Inter-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/../fonts/Inter-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Inter";
  src: url("../assets/fonts/../fonts/Inter-ExtraBold.woff2") format("woff2");
  font-weight: 800;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Impact";
  src: url("../assets/fonts/../fonts/Impact.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../assets/fonts/../fonts/Manrope-ExtraLight.ttf") format("ttf");
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../assets/fonts/../fonts/Manrope-Light.ttf") format("ttf");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../assets/fonts/../fonts/Manrope-Regular.ttf") format("ttf");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../assets/fonts/../fonts/Manrope-Medium.ttf") format("ttf");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../assets/fonts/../fonts/Manrope-SemiBold.ttf") format("ttf");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../assets/fonts/../fonts/Manrope-Bold.ttf") format("ttf");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Manrope";
  src: url("../assets/fonts/../fonts/Manrope-ExtraBold.ttf") format("ttf");
  font-weight: 800;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Sora";
  src: url("../assets/fonts/../fonts/Sora-Light.woff2") format("woff2");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Sora";
  src: url("../assets/fonts/../fonts/Sora-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Sora";
  src: url("../assets/fonts/../fonts/Sora-SemiBold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Sora";
  src: url("../assets/fonts/../fonts/Sora-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "RedHatDisplay";
  src: url("../assets/fonts/../fonts/RedHatDisplay-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "RedHatDisplay";
  src: url("../assets/fonts/../fonts/RedHatDisplay-Light.woff2") format("woff2");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "RedHatDisplay";
  src: url("../assets/fonts/../fonts/RedHatDisplay-ExtraBold.woff2") format("woff2");
  font-weight: 800;
  font-display: swap;
  font-style: normal;
}
* {
  padding: 0px;
  margin: 0px;
  border: 0px;
}

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

*::before,
*::after {
  display: inline-block;
}

img {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
}

ul li {
  list-style: none;
}

a {
  display: inline-block;
  color: inherit;
  text-decoration: none;
}

input,
button,
textarea {
  font-family: var(--font-family, sans-serif);
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  background-color: transparent;
}

input,
textarea {
  width: 100%;
}

button,
select,
option {
  cursor: pointer;
}

html,
body {
  height: 100%;
  min-width: 20rem;
}

body {
  color: var(--main-color);
  line-height: 1;
  font-family: var(--font-family, sans-serif);
  font-size: 1rem;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
}
input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]:hover,
input[type="number"]:focus {
  -moz-appearance: textfield;
}

.grayfilter {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  opacity: 0.7;
  pointer-events: none;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  -ms-scroll-chaining: none;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

.disable-scroll {
  position: relative;
  overflow: hidden;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
}

body::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  -webkit-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
  z-index: -1;
}
.lock body::after {
  background-color: transparent;
  z-index: 10;
}

.sst00{enable-background:new 0 0 140 40;}
.st0{fill-rule:evenodd;clip-rule:evenodd;fill:url(#SVGID_1_);}
.st1{fill-rule:evenodd;clip-rule:evenodd;fill:#680080;}
.st2{fill-rule:evenodd;clip-rule:evenodd;fill:#BE7ED7;}
.st3{fill:#FFFFFF;}

.wrapper {
  min-height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}
@supports (overflow: clip) {
  .wrapper {
    overflow: clip;
  }
}
.wrapper > main {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.wrapper:before {
  width: 100%;
  content: "";
  position: absolute;
  z-index: -25;
  top: 0;
  bottom: 0;
  left: 0;
  background: -webkit-gradient(
    linear,
    left bottom,
    right top,
    color-stop(5%, rgba(111, 143, 255, 0.3)),
    to(rgb(255, 255, 255))
  );
  background: linear-gradient(to top right, rgba(111, 143, 255, 0.3) 5%, rgb(255, 255, 255) 100%);
}

[class*="__container"] {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}
@media (max-width: 64rem) {
  [class*="__container"] {
    padding: 0 15px;
  }
}

.page {
  display: -ms-grid;
  display: grid;
}

.page__container {
  padding-top: 2.1875rem;
  padding-bottom: 14.6875rem;
  position: relative;
  width: 100%;
  height: 100%;
}
@media (max-width: 48em) {
  .page__container {
    padding-top: 1.875rem;
    padding-bottom: 2.5rem;
  }
}

.button {
  padding: 0.3125rem;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50px;
  background: var(--red);
  color: var(--white);
  font-weight: 600;
  position: relative;
  font-size: 0.75rem;
  font-family: var(--second-family);
  -webkit-transition: background 0.3s ease 0s;
  transition: background 0.3s ease 0s;
}
.button:focus-visible,
.button:active {
  outline: none;
  background: var(--red-transform);
}
@media (any-hover: hover) {
  .button:hover {
    background: var(--red-transform);
  }
}
.button.disable {
  background: var(--gray);
  pointer-events: none;
}
.button--blue {
  background: var(--blue);
}
.button--blue:focus-visible,
.button--blue:active {
  outline: none;
  background: var(--dark-blue);
}
@media (any-hover: hover) {
  .button--blue:hover {
    background: var(--dark-blue);
  }
}
.button--transparent {
  border: 1px solid var(--main-color);
  background: transparent;
  color: var(--main-color);
  -webkit-transition: background 0.3s ease, border 0.3s ease, color 0.3s ease;
  transition: background 0.3s ease, border 0.3s ease, color 0.3s ease;
}
.button--transparent:focus-visible,
.button--transparent:active,
.button--transparent.active {
  outline: none;
  background: var(--red);
  border-color: var(--red);
  color: var(--white);
}
@media (any-hover: hover) {
  .button--transparent:hover {
    background: var(--red);
    border-color: var(--red);
    color: var(--white);
  }
}
.button--transparent-white {
  border: 1px solid var(--base-white);
  background: transparent;
  color: var(--base-white);
  -webkit-transition: background 0.3s ease, border 0.3s ease, color 0.3s ease;
  transition: background 0.3s ease, border 0.3s ease, color 0.3s ease;
}
.button--transparent-white:focus-visible,
.button--transparent-white:active,
.button--transparent-white.active {
  outline: none;
  background: var(--green);
  border-color: var(--green);
  color: var(--base-white);
}
@media (any-hover: hover) {
  .button--transparent-white:hover {
    background: var(--green);
    border-color: var(--green);
    color: var(--base-white);
  }
}
.button__icon {
  display: none;
}
@media (max-width: 36em) {
  .button__icon {
    display: block;
    width: 0.8125rem;
    height: 1rem;
    fill: var(--base-white);
  }
  .button__icon--blue {
    fill: var(--blue);
    width: 1.125rem;
    height: 1.125rem;
  }
}
@media (max-width: 36em) {
  .button__text {
    display: none;
  }
}
.button:focus-visible,
.button:active {
  outline: none;
}
.button:focus-visible::before,
.button:active::before {
  opacity: 1;
}
@media (any-hover: hover) {
  .button:hover::before {
    opacity: 1;
  }
}
.button--fw {
  width: 100%;
}

.logo {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.logo:active {
  outline: none;
}
.logo:focus-visible {
  outline-color: var(--blue);
}

.logo-icon {
  width: 3.25rem;
  height: 2.125rem;
  fill: var(--blue);
}
.logo-icon--white {
  fill: var(--white);
  filter: brightness(0) saturate(100%) invert(100%) sepia(2%) saturate(5140%) hue-rotate(167deg) brightness(116%) contrast(100%);
}

.logo-text {
  font-family: var(--third-family);
  font-weight: 700;
  font-size: 20px;
  color: var(--white);
}
.logo-text--white {
  color: var(--white);
}

@media (max-width: 48em) {
  .logo-text {
    display: none;
  }
}

.wallet-img {
  position: relative;
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: #000;
}
.wallet-img img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
}

.wallet-icon {
  width: 1.1875rem;
  height: 1.25rem;
  fill: var(--white);
}

.icon-menu {
  display: none;
}
@media (max-width: 48em) {
  .icon-menu {
    display: block;
    position: relative;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 1.5rem;
    height: 1rem;
    -ms-grid-column-align: end;
    justify-self: end;
  }
}
@media (max-width: 48em) and (any-hover: none) {
  .icon-menu {
    cursor: default;
  }
}
@media (max-width: 48em) {
  .icon-menu span,
  .icon-menu::before,
  .icon-menu::after {
    content: "";
    right: 0;
    position: absolute;
    width: 100%;
    height: 0.125rem;
    background-color: #000;
  }
  .icon-menu--burger::before {
    top: 0;
  }
  .icon-menu--burger::after {
    bottom: 0;
  }
  .icon-menu--burger span {
    top: calc(50% - 0.0625rem);
  }
  .icon-menu--close {
    position: absolute;
    right: 0.625rem;
    top: 0.625rem;
  }
  .icon-menu--close::before {
    top: calc(50% - 0.0625rem);
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  .icon-menu--close::after {
    bottom: calc(50% - 0.0625rem);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

@media screen and (max-width: 640px) {
  .wallet__tabs-content {
    height: 100%;
    margin-bottom: 72px;
    max-height: 300px;
    overflow: auto;
  }
}

.assets {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 1.25rem;
}
.assets__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 1.25rem;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.assets__item-token {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.625rem;
}
.assets__item-icon {
  width: 2.25rem;
  height: 2.25rem;
}
.assets__item-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 0.3125rem;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
}
.assets__item-name {
  font-weight: 500;
  font-size: 0.875rem;
  color: #ffffff;
}
.assets__item-name-short {
  font-size: 0.75rem;
  color: #9db2d2;
}
.assets__item-trend {
  font-size: 0.75rem;
  color: #9db2d2;
}
.assets__item-trend--fall .assets__item-trend-pc {
  color: var(--red);
}
.assets__item-trend-sum {
  margin-right: 0.3125rem;
}
.assets__item-trend-pc {
  color: var(--green);
}
.assets__item-sum {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 0.3125rem;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: end;
}
.assets__item-amount {
  font-weight: 500;
  font-size: 0.875rem;
  color: #ffffff;
}
.assets__item-usd {
  font-size: 0.75rem;
  color: #9db2d2;
}

.token {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.375rem;
}
.token__icon {
  width: 1.875rem;
  height: 1.875rem;
}
@media (max-width: 48em) {
  .token__icon {
    width: 1.5625rem;
    height: 1.5625rem;
  }
}
.token__name {
  font-weight: 500;
  font-size: 0.875rem;
}
.token--reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.input-select-token {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 1.125rem;
}
.input-select-token__icon {
  width: 0.6875rem;
  height: 0.4375rem;
  fill: var(--dark-blue);
}

.title {
  font-family: var(--font3);
  font-weight: 800;
  font-size: 2.25rem;
}
@media (max-width: 48em) {
  .title {
    font-size: 1.875rem;
  }
}

.card {
  max-width: 38.625rem;
  margin: 0 auto;
  padding: 1.5625rem 1.5625rem 1.875rem;
  border-radius: 20px;
  -webkit-box-shadow: -2px 4px 10px 0 rgba(160, 184, 198, 0.15);
  box-shadow: -2px 4px 10px 0 rgba(160, 184, 198, 0.15);
  background: #fff;
}
@media (max-width: 48em) {
  .card {
    padding: 1.25rem;
    border-radius: 15px;
  }
}

.link-red {
  font-weight: 500;
  color: var(--red);
  text-decoration: underline;
}
@media (any-hover: hover) {
  .link-red:hover {
    text-decoration: none;
  }
}

.line-separation {
  width: 100%;
  height: 0.0625rem;
  background-color: #ebebeb;
}

.pair-token {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.75rem;
}
.pair-token__icons {
  position: relative;
  width: 2.625rem;
  height: 2.625rem;
}
.pair-token__image {
  position: absolute;
}
.pair-token__image--1 {
  top: 0;
  left: 0;
}
.pair-token__image--2 {
  bottom: 0;
  right: 0;
}
.pair-token__names {
  font-weight: 700;
  font-size: 1.25rem;
}
.pair-token__pc {
  font-size: 0.75rem;
}

.link-range {
  font-weight: 800;
  font-size: 0.75rem;
  color: var(--red);
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
}
.link-range:focus-visible,
.link-range:active {
  outline: none;
  color: var(--red-transform);
}
@media (any-hover: hover) {
  .link-range:hover {
    color: var(--red-transform);
  }
}

.icon-back {
  width: 1rem;
  height: 1.0625rem;
  fill: #9db2d2;
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}
.icon-back:focus-visible,
.icon-back:active {
  outline: none;
  fill: var(--blue);
}
@media (any-hover: hover) {
  .icon-back:hover {
    fill: var(--blue);
  }
}

.form * {
  outline: none;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 3.125rem #fff inset;
}

.form__label {
  display: block;
  margin-bottom: 0.625rem;
  font-size: 0.875rem;
}
.form__info-bottom {
  margin-top: 0.625rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.5rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-weight: 500;
  font-size: 0.75rem;
}
.form__info-bottom--right {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: end;
}
.form__info-bottom-pc {
  color: #b6c5dd;
}
.form__info-bottom-col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.5rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.form__actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.form__button {
  font-size: 0.875rem;
  height: 3.125rem;
  width: 100%;
}

.input {
  padding-left: 1.875rem;
  padding-right: 1rem;
  background-color: #fff;
  border-radius: 5px;
  outline: 1px solid #e8e8e8;
  -webkit-transition: outline 0.3s ease;
  transition: outline 0.3s ease;
}
@media (max-width: 36em) {
  .input {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}
.input .input__item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.8125rem;
}
.input__img {
  width: 1.25rem;
  height: 1.25rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 1.25rem;
  flex: 0 0 1.25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.input__control {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  width: 100%;
  height: 4.375rem;
  width: 100%;
  font-size: 1.5rem;
  font-weight: 700;
  outline: none;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 48em) {
  .input__control {
    font-size: 1rem;
    height: 3.75rem;
  }
}
.input__select {
  padding: 0.3125rem 0.625rem;
  min-width: 7.8125rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 0.3125rem;
  -webkit-box-shadow: -2px 4px 10px 0 rgba(160, 184, 198, 0.25);
  box-shadow: -2px 4px 10px 0 rgba(160, 184, 198, 0.25);
  background: #fff;
  border-radius: 20px;
  outline: 2px solid transparent;
  -webkit-transition: outline 0.3s ease 0s;
  transition: outline 0.3s ease 0s;
}
.input__select:focus-visible,
.input__select:active {
  outline: 2px solid var(--dark-blue);
}
@media (any-hover: hover) {
  .input__select:hover {
    outline: 1px solid var(--dark-blue);
  }
}
.input__select-icon {
  width: 0.6875rem;
  height: 0.4375rem;
  fill: var(--dark-blue);
}
.input__balance {
  padding-bottom: 0.75rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 0.625rem;
}
.input__balance-col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 0.4375rem;
}
.input__balance-text {
  font-size: 0.75rem;
  font-weight: 500;
}

.error-text {
  display: none;
  position: absolute;
  top: calc(100% + 2px);
  left: 0;
  font-size: 0.6875rem;
  color: red;
}
@media (max-width: 48em) {
  .error-text {
    font-size: 0.625rem;
  }
}

.input:has(.input__control:focus) {
  outline: 1px solid var(--dark-blue);
}

.input.error {
  -webkit-box-shadow: 0 0 0 1px red;
  box-shadow: 0 0 0 1px red;
}
.input.error .error-text {
  display: block;
}

select {
  position: absolute;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  left: 1.25rem;
  top: 1.25rem;
}

select:focus-visible + .select__item {
  outline: 1px solid var(--blue);
}

.select {
  position: relative;
}

.select__item {
  position: relative;
  color: var(--base-black-text);
}

.select__title {
  position: relative;
  font-size: 0.8125rem;
  background-color: #fff;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
}
@media (max-width: 36em) {
  .select__title {
    font-size: 0.6875rem;
  }
}

.select__value {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.3125rem;
  font-size: 0.875rem;
  font-weight: 500;
  height: 3.75rem;
  padding: 0px 1.25rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
@media (max-width: 36em) {
  .select__value {
    font-size: 0.6875rem;
    padding: 0px 0.75rem;
    height: 2.8125rem;
  }
}

.select__value span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.5rem;
  height: 1em;
  line-height: 1.1;
}

.select__icon {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 1.875rem;
  flex: 0 0 1.875rem;
  width: 1.875rem;
  height: 1.875rem;
}
.select__icon img {
  width: 100%;
  height: 100%;
}

.select__value::before {
  content: "";
  display: inline-block;
  -webkit-transition: -webkit-transform 0.3s ease 0s;
  transition: -webkit-transform 0.3s ease 0s;
  transition: transform 0.3s ease 0s;
  transition: transform 0.3s ease 0s, -webkit-transform 0.3s ease 0s;
  width: 11px;
  height: 7px;
  background: url("../assets/img/svg/arrow.svg") center/100% no-repeat;
}

.select__options {
  display: none;
  visibility: hidden;
  position: absolute;
  top: 3.1875rem;
  border: 1px solid #e8e8e8;
  border-radius: 0 0 5px 5px;
  min-width: 100%;
  left: 0;
  background-color: #fff;
  border-top: 0;
  padding: 0rem 0px 0.3125rem 0px;
}
@media (max-width: 36em) {
  .select__options {
    top: 2.8125rem;
  }
}

.select__option {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.625rem;
  cursor: pointer;
  padding: 0.625rem 1.25rem;
  margin: 0;
  line-height: 1.1;
  font-size: 0.875rem;
  font-weight: 500;
}
@media (max-width: 36em) {
  .select__option {
    font-size: 0.6875rem;
    padding: 0.625rem 0.75rem;
  }
}

.select._active {
  z-index: 5;
}

.select._active .select__value::before {
  -webkit-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.select._active.select__options {
  display: block;
}

.select._active .select__title {
  border-radius: 5px 5px 0 0;
}
.select._active .select__options {
  visibility: visible;
}

.modal {
  --transition-time: 0.3s;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(23, 33, 79, 0.7);
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  justify-items: center;
  cursor: pointer;
  overflow-y: auto;
  overflow-x: hidden;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity var(--transition-time) ease, visibility var(--transition-time) ease;
  transition: opacity var(--transition-time) ease, visibility var(--transition-time) ease;
}
@media (max-width: 48em) {
  .modal {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.modal__container {
  padding: 1.25rem 1.25rem 1.875rem;
  margin: 5.625rem 0.9375rem 1.875rem;
  position: relative;
  max-width: 25.625rem;
  width: 100%;
  border-radius: 20px;
  -webkit-box-shadow: -2px 4px 10px 0 rgba(160, 184, 198, 0.15);
  box-shadow: -2px 4px 10px 0 rgba(160, 184, 198, 0.15);
  background: #fff;
  cursor: default;
  display: none;
}
@media (max-width: 48em) {
  .modal__container {
    margin: 1.875rem 0.9375rem;
  }
}
@media (max-width: 36em) {
  .modal__container {
    border-radius: 24px;
    padding: 1.25rem;
    min-width: 90%;
  }
}
.modal__title {
  margin-bottom: 1.25rem;
  text-align: center;
  font-weight: 500;
  font-size: 1.25rem;
}

.modal__close {
  position: absolute;
  right: 1.0625rem;
  top: 1.0625rem;
  background-color: transparent;
  cursor: pointer;
}
.modal__close:focus-visible {
  outline: 2px solid var(--dark-blue);
}
.modal__close:focus-visible .modal__close-icon {
  fill: var(--blue);
}
.modal__close:active {
  outline: none;
}
.modal__close:active .modal__close-icon {
  fill: var(--blue);
}
.modal__close:hover .modal__close-icon {
  fill: var(--blue);
}
@media (max-width: 36em) {
  .modal__close {
    right: 0.6875rem;
    top: 0.6875rem;
  }
}

.modal__close-icon {
  width: 1.25rem;
  height: 1.25rem;
  fill: var(--dark-blue);
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}

.modal.is-open {
  opacity: 1;
  visibility: visible;
  -webkit-transition: opacity var(--transition-time) ease, visibility var(--transition-time) ease;
  transition: opacity var(--transition-time) ease, visibility var(--transition-time) ease;
}

.modal__container.modal-open {
  display: inline-block;
}

.fade {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: -webkit-transform 0.8s ease;
  transition: -webkit-transform 0.8s ease;
  transition: transform 0.8s ease;
  transition: transform 0.8s ease, -webkit-transform 0.8s ease;
}

.fade.animate-open {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.fadeInUp {
  opacity: 0;
  -webkit-transform: translateY(-100px);
  -ms-transform: translateY(-100px);
  transform: translateY(-100px);
  -webkit-transition: opacity var(--transition-time) ease, -webkit-transform var(--transition-time) ease;
  transition: opacity var(--transition-time) ease, -webkit-transform var(--transition-time) ease;
  transition: transform var(--transition-time) ease, opacity var(--transition-time) ease;
  transition: transform var(--transition-time) ease, opacity var(--transition-time) ease,
    -webkit-transform var(--transition-time) ease;
}

.fadeInUp.animate-open {
  opacity: 1;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: opacity var(--transition-time) ease, -webkit-transform var(--transition-time) ease;
  transition: opacity var(--transition-time) ease, -webkit-transform var(--transition-time) ease;
  transition: transform var(--transition-time) ease, opacity var(--transition-time) ease;
  transition: transform var(--transition-time) ease, opacity var(--transition-time) ease,
    -webkit-transform var(--transition-time) ease;
}

.custom.animate-open {
  opacity: 1;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: opacity var(--transition-time), -webkit-transform var(--transition-time);
  transition: opacity var(--transition-time), -webkit-transform var(--transition-time);
  transition: transform var(--transition-time), opacity var(--transition-time);
  transition: transform var(--transition-time), opacity var(--transition-time), -webkit-transform var(--transition-time);
}

.disable-scroll {
  position: relative;
  overflow: hidden;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
}

.modal__logo {
  margin: 0 auto;
  margin-bottom: 0.6875rem;
  display: block;
  width: 5rem;
}

.header__container {
  position: relative;
  min-height: 6.25rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  display: -ms-grid;
  display: grid;
  gap: 3.125rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-grid-columns: auto auto 1fr 15.8125rem;
  grid-template-columns: auto auto 1fr 15.8125rem;
}
@media (max-width: 64em) {
  .header__container {
    gap: 2.1875rem;
  }
}
@media (max-width: 48em) {
  .header__container {
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    row-gap: 0.625rem;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-grid-columns: 3.25rem 1fr 3.25rem;
    grid-template-columns: 3.25rem 1fr 3.25rem;
  }
}
@media (max-width: 48em) {
  .header__search {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
    grid-column: 1/4;
  }
}
.menu__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 2.5rem;
}
@media (max-width: 64em) {
  .menu__list {
    gap: 1.25rem;
  }
}
@media (max-width: 48em) {
  .menu__list {
    gap: 2.1875rem;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
.menu__link {
  font-family: var(--second-family);
  position: relative;
  color: var(--dark-blue);
  font-size: 0.75rem;
}
@media (max-width: 48em) {
  .menu__link {
    font-size: 1rem;
  }
}
.menu__link:after {
  position: absolute;
  content: "";
  width: 0;
  left: 0;
  bottom: -2px;
  height: 2px;
  background-color: currentColor;
  -webkit-transition: width 0.3s ease;
  transition: width 0.3s ease;
}
.menu__link.active {
  pointer-events: none;
  font-weight: 700;
}
.menu__link:focus-visible {
  outline-color: var(--blue);
}
@media (any-hover: hover) {
  .menu__link:hover:after {
    width: 100%;
  }
}

.search-form {
  padding: 0.625rem 1.25rem;
  height: 2.5rem;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.9375rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  outline: 1px solid #e8e8e8;
  border-radius: 50px;
  background-color: #fff;
  -webkit-transition: outline 0.3s ease;
  transition: outline 0.3s ease;
}
.search-form:has(.search-form__input:focus) {
  outline: 1px solid var(--dark-blue);
}
@media (max-width: 36em) {
  .search-form {
    gap: 0.5625rem;
  }
}
.search-form__btn:focus-visible {
  outline-color: var(--blue);
}
.search-form__btn:focus-visible .search-form__btn-icon {
  fill: var(--dark-blue);
}
.search-form__btn:active .search-form__btn:hover {
  outline: none;
}
.search-form__btn:active .search-form__btn:hover .search-form__btn-icon {
  fill: var(--dark-blue);
}
.search-form__btn-icon {
  width: 1rem;
  height: 1rem;
  fill: var(--icon);
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}
.search-form__input {
  font-size: 0.75rem;
  width: 100%;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.search-form__input::-webkit-input-placeholder {
  color: #9599a1;
}
.search-form__input::-moz-placeholder {
  color: #9599a1;
}
.search-form__input:-ms-input-placeholder {
  color: #9599a1;
}
.search-form__input::-ms-input-placeholder {
  color: #9599a1;
}
.search-form__input::placeholder {
  color: #9599a1;
}
.search-form__input:focus-visible,
.search-form__input:active {
  outline: none;
}

.wallet-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 1.125rem;
  border-radius: 12px;
}

.wallet-header img {
  border-radius: 20px;
  width: 40px;
  height: 40px;
}

.header__address_container {
  border: #ffffff 1px solid;
  border-radius: 50px;
  padding: 7px 50px 7px 20px;
  color: #ffffff;
  position: relative;
  display: flex;
}

.header__address_container svg {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  fill: white;
}

.token-symbol-container1 {
  border-radius: 20px;
  box-shadow: -2px 4px 10px 0 rgba(160, 184, 198, 0.25);
  padding: 5px 0;
  font-size: 14px;
}

.card__button-wrapper {
  margin-top: 0px;
}


@media (max-width: 64em) {
  .wallet-header {
    gap: 0.625rem;
  }
}
@media (max-width: 48em) {
  .wallet-header {
    display: none;
  }
}
.wallet-header__btn {
  padding: 0.3125rem 0.9375rem 0.3125rem 1.25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 0.9375rem;
  border: 2px solid var(--dark-blue);
  border-radius: 50px;
  width: 100%;
  min-width: 11.25rem;
  height: 2.5rem;
  -webkit-transition: border 0.3s ease;
  transition: border 0.3s ease;
}
.wallet-header__btn:active,
.wallet-header__btn:focus-visible {
  outline: none;
  border: 2px solid var(--blue);
}
@media (any-hover: hover) {
  .wallet-header__btn:hover {
    border: 2px solid var(--blue);
  }
}
.wallet-header__address {
  font-family: var(--second-family);
  font-size: 0.75rem;
  color: var(--dark-blue);
}
.wallet-header__arrow {
  width: 0.75rem;
  height: 0.5rem;
  fill: var(--dark-blue);
}

.wallet {
  padding: 1.25rem 1.5rem;
  position: fixed;
  top: 0;
  bottom: 0;
  right: -100%;
  height: 100%;
  width: 100%;
  max-width: 22.75rem;
  z-index: 11;
  overflow: auto;
  background-color: #fff;
  -webkit-box-shadow: -2px 4px 10px 0 rgba(160, 184, 198, 0.15);
  box-shadow: -2px 4px 10px 0 rgba(160, 184, 198, 0.15);
  border-radius: 20px;
  opacity: 0;
  visibility: hidden;
  height: calc(100% - 2.625rem);
  -webkit-transition: right 0.3s ease, opacity 0.3s ease;
  transition: right 0.3s ease, opacity 0.3s ease;
}
.menu-open .wallet {
  opacity: 1;
  top: 0.75rem;
  bottom: 0;
  -webkit-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
  visibility: visible;
  right: 0.9375rem;
}
@media (max-width: 48em) {
  .wallet {
    height: 100%;
  }
  .menu-open .wallet {
    top: 0;
    right: 0;
    border-radius: 0;
  }
}
@media (max-width: 26.5625em) {
  .wallet {
    max-width: 100%;
  }
}

.wallet__top {
  margin-bottom: 1.5625rem;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto 1fr auto;
  grid-template-columns: auto 1fr auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.9375rem;
}
.wallet__close-btn:focus-visible {
  outline: 2px solid var(--dark-blue);
}
.wallet__close-btn:active {
  outline: none;
}
.wallet__close-btn:active .wallet__close-icon {
  fill: var(--dark-blue);
}
@media (any-hover: hover) {
  .wallet__close-btn:hover .wallet__close-icon {
    fill: var(--dark-blue);
  }
}
.wallet__close-icon {
  width: 1rem;
  height: 1.0625rem;
  fill: #9db2d2;
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}
.wallet__user {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.6875rem;
}
.wallet__user-address {
  font-size: 0.875rem;
}
.wallet__exit-btn:focus-visible {
  outline: 2px solid var(--dark-blue);
}
.wallet__exit-btn:active {
  outline: none;
}
.wallet__exit-btn:active .wallet__exit-icon {
  fill: var(--red-transform);
}
@media (any-hover: hover) {
  .wallet__exit-btn:hover .wallet__exit-icon {
    fill: var(--red-transform);
  }
}
.wallet__exit-icon {
  width: 1.125rem;
  height: 1.125rem;
  fill: var(--red);
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}
.wallet__actions {
  margin-bottom: 1.375rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
}
.wallet__action {
  padding: 1.25rem;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  border-radius: 12px;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}
.wallet__action:focus-visible {
  outline: 2px solid var(--dark-blue);
}
.wallet__action:active {
  outline: none;
  background: #05347a;
}
.wallet__action--buy {
  background-color: var(--blue);
}
@media (any-hover: hover) {
  .wallet__action--buy:hover {
    background: #05347a;
  }
}
.wallet__action--view {
  background-color: var(--red);
}
@media (any-hover: hover) {
  .wallet__action--view:hover {
    background: #b91414;
  }
}
.wallet__action-icon {
  margin-bottom: 1.25rem;
  width: 1.3125rem;
  height: 1.125rem;
  fill: var(--white);
}
.wallet__action-text {
  font-weight: 700;
  font-size: 0.875rem;
  color: var(--white);
}
.wallet__line {
  margin-bottom: 1.375rem;
  width: 100%;
  height: 1px;
  background-color: #2B2B2B;
}
.wallet__tabs-navigation {
  margin-bottom: 1.5625rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1.5rem;
}
.wallet__tab-title {
  position: relative;
  font-size: 0.75rem;
}
.wallet__tab-title:after {
  position: absolute;
  content: "";
  width: 0.3125rem;
  height: 0.3125rem;
  border-radius: 50%;
  right: -0.5rem;
  top: 50%;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
  background-color: var(--red);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}
.wallet__tab-title.active {
  pointer-events: none;
}
.wallet__tab-title.active:after {
  opacity: 1;
  visibility: visible;
}
.wallet__balance {
  margin-bottom: 1.5625rem;
}
.wallet-balance__label {
  display: block;
  margin-bottom: 0.25rem;
  font-size: 0.75rem;
}
.wallet-balance__total {
  margin-bottom: 0.5rem;
  font-weight: 700;
  font-size: 1.5rem;
}
.wallet-balance__trend {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.875rem;
}
.wallet-balance__trend--fall .wallet-balance__trend-icon {
  filter: brightness(0) saturate(100%) invert(61%) sepia(81%) saturate(1535%) hue-rotate(316deg) brightness(105%) contrast(101%);
  -webkit-transform: rotate(-360deg);
  -ms-transform: rotate(-360deg);
  transform: rotate(-360deg);
}
.wallet-balance__trend--fall .wallet-balance__trend-pc {
  color: var(--red);
}
.wallet-balance__trend-icon {
  margin-right: 0.5rem;
  width: 0.625rem;
  height: 0.75rem;
  filter: brightness(0) saturate(100%) invert(61%) sepia(10%) saturate(1616%) hue-rotate(113deg) brightness(97%) contrast(96%);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.wallet-balance__trend-icon--fall {
  filter: brightness(0) saturate(100%) invert(61%) sepia(81%) saturate(1535%) hue-rotate(316deg) brightness(105%) contrast(101%);
}
.wallet-balance__trend-sum {
  margin-right: 0.25rem;
}
.wallet-balance__trend-pc {
  color: var(--green);
}
.wallet-balance__trend-pc--fall {
  color: var(--red);
}

.swap {
  margin: 0 auto;
  max-width: 38.5625rem;
  width: 100%;
}
.swap:not(:last-child) {
  margin-bottom: 3.75rem;
}
@media (max-width: 48em) {
  .swap:not(:last-child) {
    margin-bottom: 2.5rem;
  }
}
.swap__title {
  margin: 0 auto;
  padding-bottom: 60px;
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 72px;
  line-height: 100%;
  letter-spacing: -0.06em;
  text-align: center;
  background: -o-linear-gradient(337deg, rgba(255, 253, 253, 0.8) 4.34%, rgba(196, 121, 255, 0.8) 41.45%, rgba(212, 156, 255, 0.8) 80.29%);
  background: linear-gradient(113deg, rgba(255, 253, 253, 0.8) 4.34%, rgba(196, 121, 255, 0.8) 41.45%, rgba(212, 156, 255, 0.8) 80.29%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  mix-blend-mode: hard-light;

  @media (max-width: 768px) {
    font-size: 42px !important;
    padding-bottom: 36px !important;
  }
}
.deltapay__title {
  margin: 0 auto;
  padding-bottom: 64px;
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 72px;
  line-height: 100%;
  letter-spacing: -0.06em;
  text-align: center;
  background: -o-linear-gradient(337deg, rgba(255, 253, 253, 0.8) 4.34%, rgba(196, 121, 255, 0.8) 41.45%, rgba(212, 156, 255, 0.8) 80.29%);
  background: linear-gradient(113deg, rgba(255, 253, 253, 0.8) 4.34%, rgba(196, 121, 255, 0.8) 41.45%, rgba(212, 156, 255, 0.8) 80.29%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  mix-blend-mode: hard-light;

  @media (max-width: 768px) {
    font-size: 52px;
  }
}

.buy-crypto__texts {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 24px;
}

.buy-crypto__label {
  font-weight: 500;
  font-size: 24px;
  line-height: 100%;
  direction: rtl;
  color: var(--white);
}

.buy-crypto__text {
  direction: rtl;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  color: var(--white);
}

.swap__form:not(:last-child) {
  margin-bottom: 2.125rem;
}
.swap__form-items:not(:last-child) {
  margin-bottom: 2.125rem;
}
.swap__form-items--confirm {
  margin-bottom: 1.875rem;
}
.swap__form-separation {
  margin-bottom: 1.875rem;
}
.swap__form-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: 0.3125rem;
  margin-bottom: 0.3125rem;
  width: 0.875rem;
  height: 1.125rem;
}
.swap__form-button:not(:last-child) {
  margin-bottom: 1.25rem;
}
.swap__protocol {
  padding-top: 34px;
  text-align: center;
  font-family: var(--font3);
}
.swap-details:not(:last-child) {
  margin-bottom: 2rem;
}
.swap-details__rates {
  font-size: 0.875rem;
}
.swap-details__rates:not(:last-child) {
  margin-bottom: 1.25rem;
}
.swap-details__table {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 0.75rem;
}
.swap-details__table-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.75rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 0.75rem;
  color: var(--light-gray);
}
.swap-details__table-data--red {
  color: var(--red);
}

.review-swap__item:not(:last-child) {
  margin-bottom: 1.25rem;
}
.review-swap__item-label {
  margin-bottom: 0.75rem;
  display: block;
  font-size: 0.875rem;
}
.review-swap__item-token {
  margin-bottom: 0.75rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 1.25rem;
}
.review-swap__item-amount {
  font-weight: 700;
  font-size: 1.5rem;
}
@media (max-width: 48em) {
  .review-swap__item-amount {
    font-size: 0.9375rem;
  }
}
.review-swap__item-sum {
  font-size: 0.75rem;
}
.review-swap__item-pc {
  color: #b6c5dd;
}
.review-swap__separation:not(:last-child) {
  margin-bottom: 1.25rem;
}

.modal-select-token__search {
  margin-bottom: 1rem;
}
.modal-select-token__list {
  margin-bottom: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 0.75rem;
}
.modal-select-token__btn {
  padding: 0.3125rem 0.625rem;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 6.0625rem;
  flex: 0 1 6.0625rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: start;
  border: 1px solid #e8e8e8;
  border-radius: 50px;
  -webkit-transition: border 0.3s ease, background 0.3s ease;
  transition: border 0.3s ease, background 0.3s ease;
}
.modal-select-token__btn.active {
  pointer-events: none;
  background-color: #f1f1f1;
  border: 1px solid transparent;
}
.modal-select-token__btn:focus-visible {
  outline: 2px solid var(--dark-blue);
}
.modal-select-token__btn:active {
  outline: none;
  border: 1px solid var(--dark-blue);
}
@media (any-hover: hover) {
  .modal-select-token__btn:hover {
    background-color: #f1f1f1;
    border: 1px solid transparent;
  }
}
.modal-select-token__separation {
  margin-bottom: 1rem;
}
.modal-select-token__title {
  margin-bottom: 1rem;
  font-weight: 500;
}
.modal-select-token__assets {
  gap: 0.625rem;
}
.modal-select-token__item {
  padding: 0.3125rem;
  border-radius: 50px;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}
.modal-select-token__item:focus-visible {
  outline-offset: 7px;
  outline: 2px solid var(--dark-blue);
  border-radius: 50px;
}
.modal-select-token__item:active {
  outline-offset: 7px;
  outline: 1px solid var(--dark-blue);
  border-radius: 50px;
}
@media (any-hover: hover) {
  .modal-select-token__item:hover {
    background-color: #f1f1f1;
  }
}

.modal-confirm-swap__review {
  margin-bottom: 1.875rem;
}
.modal-confirm-swap__separation {
  margin-bottom: 1.6875rem;
}
.confirm-stet {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 1.375rem;
}
.confirm-stet__item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.875rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.confirm-stet__item:not(:last-child):after {
  position: absolute;
  content: "";
  border-left: 1px dashed #50af95;
  height: 0.875rem;
  width: 0.0625pxrem;
  top: 2.75rem;
  left: 1.25rem;
}
.confirm-stet__check,
.confirm-stet__swap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
}
.confirm-stet__check {
  background-color: var(--green);
}
.confirm-stet__swap {
  background-color: var(--blue);
}
.confirm-stet__icon {
  width: 1rem;
  height: 1rem;
  fill: var(--white);
}
.confirm-stet__text {
  line-height: 140%;
  font-size: 0.875rem;
}
.confirm-stet__text-gray {
  display: block;
  color: var(--light-gray);
}

.modal-success {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: stretch;
  -ms-flex-pack: stretch;
  justify-content: stretch;
  gap: 1.6875rem;
}
.modal-success__icon {
  width: 10.5rem;
  height: 10.5rem;
  fill: var(--blue);
}
.modal-success__title {
  font-family: var(--font3);
  font-weight: 800;
  font-size: 2.25rem;
}
.modal-success__swap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.75rem;
}
.modal-success__swap-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.5625rem;
}
.modal-success__swap-amount {
  font-size: 0.875rem;
}
.modal-success__swap-icon {
  width: 1rem;
  height: 0.75rem;
  fill: #000;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.modal-success__button {
  min-width: 17.875rem;
  height: 3.125rem;
}

.modal-transaction-success {
  min-height: 25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 1.6875rem;
}
.modal-transaction-success__icon {
  width: 10.5rem;
  height: 10.5rem;
  fill: var(--blue);
}
.modal-transaction-success__title {
  font-family: var(--font3);
  font-weight: 800;
  font-size: 1.5rem;
}
.modal-transaction-success__button {
  min-width: 17.875rem;
  height: 3.125rem;
}

.pool-position__header {
  margin-bottom: 1.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.pool-position__title {
  font-family: var(--font3);
  font-weight: 800;
  font-size: 1.5rem;
  color: var(--main-color);
}
@media (max-width: 48em) {
  .pool-position__title {
    font-size: 1.125rem;
  }
}
.pool-position__btn {
  min-width: 10.3125rem;
  height: 2.5rem;
}
@media (max-width: 48em) {
  .pool-position__btn {
    min-width: 8.125rem;
    height: 2.1875rem;
  }
}
.pool-position__connect {
  padding-top: 3.75rem;
  padding-bottom: 5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 1.625rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media (max-width: 48em) {
  .pool-position__connect {
    padding-top: 2.5rem;
    padding-bottom: 3.75rem;
  }
}
.pool-position__connect-icon {
  width: 3rem;
  height: 2.5625rem;
  fill: transparent;
  stroke: #000;
  stroke-width: 2px;
}
.pool-position__connect-text {
  max-width: 17.5rem;
  text-align: center;
  font-family: var(--font3);
  font-size: 1.125rem;
}
.pool-position__connect-btn {
  min-width: 10.3125rem;
  height: 2.5rem;
}
@media (max-width: 48em) {
  .pool-position__connect-btn {
    height: 2.1875rem;
  }
}

.pool-position__items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 0.9375rem;
}
.item-position {
  padding: 1rem 1.25rem 1.875rem;
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-grid-columns: 1fr auto;
  grid-template-columns: 1fr auto;
  gap: 0.625rem;
}
.item-position__amount {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.5625rem;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1/3;
  font-size: 0.75rem;
}
.item-position__amount-line {
  width: 1.1875rem;
  height: 0.5rem;
  stroke-width: 1px;
  stroke: var(--light-gray);
}
.liquidity-range__header {
  margin-bottom: 1.25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.liquidity-range__actions {
  margin-bottom: 1.25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 0.625rem;
}
@media (max-width: 48em) {
  .liquidity-range__actions {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
}
.liquidity-range__btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 0.3125rem;
}
.liquidity-range__btn {
  min-width: 9.375rem;
  height: 2.5rem;
}
@media (max-width: 48em) {
  .liquidity-range__btn {
    min-width: 6.25rem;
    height: 2.1875rem;
    width: 100%;
  }
}
.liquidity-info {
  margin-bottom: 1.25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1.125rem;
}
@media (max-width: 48em) {
  .liquidity-info {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.liquidity-info__left {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 43%;
  flex: 0 1 43%;
  padding: 1.5625rem 1.5625rem 2.1875rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 0.625rem;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: var(--white);
  border-radius: 6px;
  background: url("../assets/img/bg-pool.jpg") 0 0 / cover no-repeat;
}
@media (max-width: 48em) {
  .liquidity-info__left {
    padding: 1.25rem;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}
.liquidity-info__pair {
  font-family: var(--font3);
  font-weight: 300;
  font-size: 1.625rem;
}
@media (max-width: 48em) {
  .liquidity-info__pair {
    font-size: 1.25rem;
  }
}
.liquidity-info__pair-names {
  margin-bottom: 0.3125rem;
}
.liquidity-info__range-text {
  font-size: 0.75rem;
  line-height: 140%;
}
@media (max-width: 48em) {
  .liquidity-info__range-text {
    text-align: end;
  }
}
.liquidity-info__items {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 57%;
  flex: 0 1 57%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 0.625rem;
}

.item-liquidity {
  padding: 0.9375rem 1.25rem;
  border: 1px solid #e8e8e8;
  border-radius: 6px;
}
.item-liquidity__header {
  margin-bottom: 0.625rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 0.9375rem;
}
.item-liquidity__title {
  font-size: 0.875rem;
}
.item-liquidity__link {
  min-width: 5.625rem;
  height: 1.5rem;
  font-size: 0.5625rem;
}
.item-liquidity__sum {
  margin-bottom: 0.625rem;
  font-weight: 700;
  font-size: 32px;
}
@media (max-width: 48em) {
  .item-liquidity__sum {
    font-size: 24px;
  }
}
.item-liquidity__table {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 0.625rem;
}
.item-liquidity__table-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1.1875rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.item-liquidity__table-head {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.item-liquidity__table-data {
  font-size: 0.875rem;
}

.price-range__header {
  margin-bottom: 0.3125rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: end;
  gap: 0.625rem;
}
.price-range__title {
  font-size: 0.875rem;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}
.price-range__button {
  padding: 0.3125rem;
  min-width: 5.5625rem;
  height: 1.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-weight: 500;
  font-size: 0.6875rem;
  color: var(--blue);
  border: 1px solid var(--blue);
  border-radius: 5px;
  -webkit-transition: color 0.3s ease, border 0.3s ease;
  transition: color 0.3s ease, border 0.3s ease;
}
@media (any-hover: hover) {
  .price-range__button:hover {
    color: var(--dark-blue);
    border: 1px solid var(--dark-blue);
  }
}
.price-range__tabs {
  padding: 0.3125rem 0.6875rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 0.625rem;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  height: 1.5rem;
}
.price-range__tab {
  font-weight: 500;
  font-size: 0.75rem;
  color: var(--light-gray);
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
}
.price-range__tab:active {
  color: var(--main-color);
}
@media (any-hover: hover) {
  .price-range__tab:hover {
    color: var(--main-color);
  }
}
.price-range__tab.active {
  pointer-events: none;
  color: var(--main-color);
}
.price-range__items {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 0.625rem;
}
.item-price-range {
  padding: 1.375rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.5rem;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  color: var(--light-gray);
  -webkit-transition: border 0.3s ease;
  transition: border 0.3s ease;
}
@media (any-hover: hover) {
  .item-price-range:hover {
    border: 1px solid var(--blue);
  }
}
.item-price-range__title {
  font-weight: 500;
  font-size: 0.75rem;
}
.item-price-range__price {
  font-weight: 700;
  font-size: 1.5rem;
  color: var(--main-color);
}
@media (max-width: 48em) {
  .item-price-range__price {
    font-size: 1.125rem;
  }
}
.item-price-range__pair {
  font-weight: 500;
  font-size: 0.75rem;
}
.item-price-range__descr {
  max-width: 10rem;
  text-align: center;
  font-size: 0.625rem;
}
.item-price-range--current {
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1/3;
}
.item-price-range--current-graph {
  padding: 0;
  border: none;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1/3;
}

.liquidity__title {
  margin-bottom: 1.25rem;
  font-weight: 500;
  font-size: 1.25rem;
  text-align: center;
}
.liquidity__header {
  margin-bottom: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.9375rem;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.liquidity__table {
  margin-bottom: 0.875rem;
  padding: 1.25rem;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 0.75rem;
}
.liquidity__table--reverse .liquidity__table-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
@media (max-width: 48em) {
  .liquidity__table {
    padding: 0.9375rem;
  }
}
.liquidity__table-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 1.25rem;
}
@media (max-width: 48em) {
  .liquidity__table-row {
    font-size: 0.875rem;
  }
}
.liquidity__table-text {
  margin-top: 0.625rem;
  font-size: 0.6875rem;
}
.liquidity__price {
  margin-bottom: 0.875rem;
}
.liquidity__deposit {
  margin-bottom: 0.875rem;
}
.liquidity__deposit-title {
  margin-bottom: 0.3125rem;
  font-size: 0.875rem;
}
.liquidity__button {
  width: 100%;
  height: 3rem;
}
@media (max-width: 48em) {
  .liquidity__button {
    height: 2.5rem;
  }
}

.liquidity__amount-title {
  margin-bottom: 0.375rem;
  font-weight: 500;
  font-size: 0.75rem;
}
.amount-range {
  margin-bottom: 0.625rem;
  padding: 1.25rem 1.75rem 1.75rem;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
}
@media (max-width: 48em) {
  .amount-range {
    padding: 0.9375rem;
  }
}
.amount-range__nav {
  margin-bottom: 1.25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 0.9375rem;
}
@media (max-width: 48em) {
  .amount-range__nav {
    gap: 0.625rem;
  }
}
.amount-range__value {
  font-weight: 700;
  font-size: 3rem;
}
@media (max-width: 48em) {
  .amount-range__value {
    font-size: 1.25rem;
  }
}
.amount-range__tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.625rem;
}
@media (max-width: 48em) {
  .amount-range__tabs {
    gap: 0.1875rem;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: end;
  }
}
.amount-range__tab {
  padding: 0.5rem;
  font-family: var(--second-family);
  min-width: 4.375rem;
  height: 1.875rem;
  font-size: 0.75rem;
}
@media (max-width: 48em) {
  .amount-range__tab {
    min-width: auto;
    height: 1.4375rem;
    font-size: 0.6875rem;
  }
}

.add-liquidity__header {
  margin-bottom: 1.875rem;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 5.75rem 1fr 5.75rem;
  grid-template-columns: 5.75rem 1fr 5.75rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.9375rem;
}
@media (max-width: 48em) {
  .add-liquidity__header {
    -ms-grid-columns: auto auto;
    grid-template-columns: auto auto;
  }
}
.add-liquidity__header-title {
  font-weight: 500;
  font-size: 1.25rem;
  text-align: center;
}
@media (max-width: 48em) {
  .add-liquidity__header-title {
    -webkit-box-ordinal-group: 6;
    -ms-flex-order: 5;
    order: 5;
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1/3;
    font-size: 1.125rem;
  }
}
.add-liquidity__header-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 0.625rem;
}
@media (max-width: 48em) {
  .add-liquidity__header-actions {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: end;
  }
}
.add-liquidity__header-clear {
  color: var(--red);
  font-size: 0.75rem;
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
}
@media (any-hover: hover) {
  .add-liquidity__header-clear:hover {
    color: var(--red-transform);
  }
}
@media (any-hover: hover) {
  .add-liquidity__header-settings:hover .add-liquidity__header-settings-icon {
    fill: var(--blue);
  }
}
.add-liquidity__header-settings-icon {
  width: 1.25rem;
  height: 1.25rem;
  fill: #9db2d2;
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}
.add-liquidity__items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 1rem;
}
.add-liquidity__item-title {
  margin-bottom: 0.4375rem;
  font-size: 0.875rem;
}
.add-liquidity__actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 1rem;
}
@media (max-width: 48em) {
  .add-liquidity__actions {
    gap: 0.625rem;
  }
}
.add-liquidity__btn {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  height: 3rem;
}
@media (max-width: 48em) {
  .add-liquidity__btn {
    height: 2.5rem;
  }
}

.select-pair {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 0.625rem;
}
.select-pair__tokens {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 1rem;
}
.select-pair__token {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 50%;
  flex: 0 1 50%;
}
.select-pair__fee-tier {
  padding: 1.25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.9375rem;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
}
.select-pair__fee-tier-text {
  font-weight: 500;
  font-size: 0.875rem;
}
.select-pair__fee-tier-link {
  font-size: 0.875rem;
}
.select-pair__btns {
  display: -ms-grid;
  display: grid;
  gap: 0.625rem;
  grid-template-columns: repeat(auto-fit, minmax(8.125rem, 1fr));
}
.select-pair__btn {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1.25rem;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
  -webkit-transition: border 0.3s ease;
  transition: border 0.3s ease;
}
.select-pair__btn::after {
  content: "";
  display: none;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  background-color: var(--red);
  background-image: url("../assets/img/svg/check.svg");
  background-position: center;
  background-size: 0.625rem 0.625rem;
  background-repeat: no-repeat;
}
.select-pair__btn.active {
  border: 1px solid var(--red);
}
.select-pair__btn.active:after {
  display: block;
}
.select-pair__btn:focus-visible,
.select-pair__btn:active {
  outline: none;
  border: 1px solid var(--red);
}
@media (any-hover: hover) {
  .select-pair__btn:hover {
    border: 1px solid var(--red);
  }
}
.select-pair__btn-pc {
  font-weight: 600;
  font-size: 0.875rem;
}
.select-pair__btn-tex {
  font-size: 0.6875rem;
  text-align: start;
}

.set-price-range {
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1/3;
  padding: 1.375rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 1.25rem;
  gap: 0.5rem;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
}
.set-price-range--current {
  padding: 0;
  border: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
}
.set-price-range__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 0.5rem;
}
.set-price-range__title {
  font-weight: 500;
  font-size: 0.75rem;
}
.set-price-range__price {
  font-weight: 700;
  width: 100%;
  font-size: 1.5rem;
  outline: none;
}
@media (max-width: 48em) {
  .set-price-range__price {
    font-size: 1.125rem;
  }
}
.set-price-range__pair {
  font-weight: 500;
  font-size: 0.75rem;
}
.set-price-range__count {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 0.625rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.set-price-range__count-plus,
.set-price-range__count-minus {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  width: 1.25rem;
  height: 1.5rem;
  -webkit-transition: border 0.3s ease, color 0.3s ease;
  transition: border 0.3s ease, color 0.3s ease;
}
.set-price-range__count-plus:active,
.set-price-range__count-plus:focus-visible,
.set-price-range__count-minus:active,
.set-price-range__count-minus:focus-visible {
  outline: none;
  border: 1px solid var(--blue);
  color: var(--blue);
}
@media (any-hover: hover) {
  .set-price-range__count-plus:hover,
  .set-price-range__count-minus:hover {
    border: 1px solid var(--blue);
    color: var(--blue);
  }
}
.set-price-range__row {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: end;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 1.25rem;
}
.set-price-range__zoom-btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.9375rem;
}
.set-price-range__zoom-btn {
  width: 1.25rem;
  height: 1.25rem;
}
.set-price-range__graph {
  max-width: 35.4375rem;
}
.set-price-range__graph img {
  width: 100%;
}

.deposit-amount {
  margin-bottom: 1.25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 0.625rem;
}
.modal-waiting {
  min-height: 25rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.modal-waiting__progress {
  margin-bottom: 1.625rem;
  width: 8.125rem;
  height: 8.125rem;
  fill: #034ab5;
}
.modal-waiting__progress img {
  width: 100%;
}
.modal-waiting__title {
  margin-bottom: 0.9375rem;
  font-family: var(--font3);
  font-weight: 800;
  font-size: 1.5rem;
}
.modal-waiting__liquidity {
  margin-bottom: 0.9375rem;
  font-size: 0.875rem;
}
.modal-waiting__text {
  font-weight: 300;
  font-size: 0.75rem;
}

.slider-container {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
}

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(var(--blue)),
    color-stop(50%, var(--blue)),
    color-stop(50%, #ddd),
    to(#ddd)
  );
  background: linear-gradient(to right, var(--blue) 0%, var(--blue) 50%, #ddd 50%, #ddd 100%);
  outline: none;
  -webkit-transition: background 0.2s;
  transition: background 0.2s;
  border-radius: 5px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 30px;
  height: 30px;
  background: var(--blue);
  cursor: pointer;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  -webkit-transition: background 0.2s;
  transition: background 0.2s;
}

input[type="range"]::-moz-range-thumb {
  width: 30px;
  height: 30px;
  background: var(--blue);
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  -moz-transition: background 0.2s;
  transition: background 0.2s;
}

input[type="range"]::-ms-thumb {
  width: 30px;
  height: 30px;
  background: var(--blue);
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  -ms-transition: background 0.2s;
  transition: background 0.2s;
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 10px;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

.spollers {
  display: -ms-grid;
  display: grid;
  gap: 15px;
}

.spollers__item {
  font-size: 1.25rem;
}

.spollers__title {
  width: 100%;
  cursor: default;
  text-align: left;
  border: 1px solid #eee;
  padding: 0.625rem 0.9375rem;
  border-radius: 0.3125rem;
  position: relative;
  list-style: none;
}
.spollers__title::marker,
.spollers__title::-webkit-details-marker {
  display: none;
}
._spoller-init .spollers__title {
  cursor: pointer;
  /*
  ._spoller-init & {
  	position: relative;
  	width: 100%;
  	text-align: left;
  	&::before,
  	&::after {
  		content: "";
  		position: absolute;
  		right: 0;
  		top: toRem(12);
  		height: toRem(1);
  		transition: all 0.3s ease 0s;
  		width: toRem(10);
  		background-color: #000;
  	}
  	&::before {
  		transform: rotate(40deg);
  		right: toRem(7);
  	}
  	&::after {
  		transform: rotate(-40deg);
  	}
  }
  &._spoller-active {
  	&::before {
  		transform: rotate(-40deg);
  	}
  	&::after {
  		transform: rotate(40deg);
  	}
  }
  */
}
._spoller-init .spollers__title::before,
._spoller-init .spollers__title::after {
  content: "";
  position: absolute;
  right: 10px;
  top: 50%;
  background-color: #000;
  height: 2px;
  width: 15px;
  -webkit-transition: -webkit-transform 0.5s ease 0s;
  transition: -webkit-transform 0.5s ease 0s;
  transition: transform 0.5s ease 0s;
  transition: transform 0.5s ease 0s, -webkit-transform 0.5s ease 0s;
}
._spoller-init .spollers__title::before {
  -webkit-transform: translate(-75%, -50%) rotate(40deg);
  -ms-transform: translate(-75%, -50%) rotate(40deg);
  transform: translate(-75%, -50%) rotate(40deg);
}
._spoller-init .spollers__title::after {
  -webkit-transform: translate(0, -50%) rotate(-40deg);
  -ms-transform: translate(0, -50%) rotate(-40deg);
  transform: translate(0, -50%) rotate(-40deg);
}
._spoller-init .spollers__title._spoller-active::before {
  -webkit-transform: translateX(-75%) rotate(-40deg);
  -ms-transform: translateX(-75%) rotate(-40deg);
  transform: translateX(-75%) rotate(-40deg);
}
._spoller-init .spollers__title._spoller-active::after {
  -webkit-transform: rotate(40deg);
  -ms-transform: rotate(40deg);
  transform: rotate(40deg);
}

.spollers__body {
  padding: 1.25rem;
  border-radius: 0.3125rem;
  border: 1px solid #eee;
}

.main-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 1rem;
  max-width: 860px;
  margin: 0 auto;
  margin-top: 60px;
}

@media (max-width: 1280px) {
  .main-info {
    max-width: 640px;
  }
}

@media (max-width: 1024px) {
  .main-info {
    max-width: 480px;
    margin: 0 8px;
    margin-top: 40px;
  }
}

.exchange-provide {
  margin: 40px 0;
}

.exchange-provide__columns {
  display: grid;
  /* grid-template-columns: repeat(2, 1fr); */
  grid-template-columns: repeat(1, 1fr);
  -webkit-column-gap: 40px;
     -moz-column-gap: 40px;
          column-gap: 40px;
  row-gap: 40px;
}

.exchange-provide__made {
  display: grid;
  padding: 28px 30px;
  min-height: 330px;
  border-radius: 16px;
  background-image: linear-gradient(0deg, rgba(10, 0, 18, 0.7), rgba(10, 0, 18, 0.7) 100%), url("../assets/img/main-bg/01.jpg");
  background-position: 5%;
  background-repeat: no-repeat;
  background-size: auto 105%;
}

.exchange-provide__made-label {
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 14px;
  line-height: 110%;
  color: var(--white);
}

.exchange-provide__made-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 10px;
  max-height: 230px;
  overflow-y: auto;
  scrollbar-color: var(--pink) var(--bg-color);
  scrollbar-width: thin;
}

.exchange-provide__made-list::-webkit-scrollbar {
  width: 4px;
}

.exchange-provide__made-list::-webkit-scrollbar-track {
  background: var(--bg-color);
}

.exchange-provide__made-list::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: var(--pink);
}

.exchange-provide__made-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-column-gap: 20px;
     -moz-column-gap: 20px;
          column-gap: 20px;
}

.exchange-provide__made-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 8px;
     -moz-column-gap: 8px;
          column-gap: 8px;
}

.exchange-provide__made-name {
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  color: var(--white);
}

.exchange-provide__made-network {
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  color: #9db2d2;
}

.exchange-provide__made-sums {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-column-gap: 8px;
     -moz-column-gap: 8px;
          column-gap: 8px;
}

.exchange-provide__made-sum {
  font-family: var(--second-family);
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  text-align: right;
  color: var(--white);
}

.exchange-provide__made-sum--green {
  color: #4dfd30;
}

.exchange-provide__empty {
  display: grid;
  padding: 36px;
  min-height: 330px;
  border-radius: 16px;
  background-image: linear-gradient(0deg, rgba(10, 0, 18, 0.7), rgba(10, 0, 18, 0.7) 100%), url("../assets/img/main-bg/02.jpg");
  background-position: 5%;
  background-repeat: no-repeat;
  background-size: auto 105%;
}

.exchange-provide__empty-text {
  margin-top: auto;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: #fff;
}

@media (max-width: 767.98px) {

  .exchange-provide {
    margin: 24px 0;
  }

  .exchange-provide__columns {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 24px;
  }

  .exchange-provide__made {
    padding: 20px 16px;
  }

  .exchange-provide__made-label {
    margin-bottom: 20px;
  }

  .exchange-provide__empty {
    padding: 24px 16px;
  }

  .accordion__item.active .accordion__content {
    padding-top: 10px;
    row-gap: 4px;
  }

  .accordion__header {
    -webkit-column-gap: 12px;
       -moz-column-gap: 12px;
            column-gap: 12px;
    padding: 10px 16px;
  }

  .accordion__header-title {
    font-size: 14px;
  }

  .accordion__text {
    font-size: 12px;
    line-height: 140%;
  }

  .accordion__link-video {
    -webkit-column-gap: 4px;
       -moz-column-gap: 4px;
            column-gap: 4px;
    font-size: 12px;
  }

  .footer__inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 40px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

  .footer__logo {
    margin-top: 0;
  }

  .footer__nav {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-column-gap: 24px;
       -moz-column-gap: 24px;
            column-gap: 24px;
    row-gap: 24px;
  }
}
.cards {
  grid-gap: 1rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr;
}

.cards__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  -webkit-column-gap: 40px;
     -moz-column-gap: 40px;
          column-gap: 40px;
  row-gap: 40px;
}

.cards__link {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 16px;
  height: 136px;
  overflow: hidden;
}

.cards__img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  -webkit-transition: 0.3s linear;
  -o-transition: 0.3s linear;
  transition: 0.3s linear;
}

.cards__name {
  position: absolute;
  bottom: 28px;
  left: 36px;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  text-decoration: underline;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
  color: var(--white);
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

@media (max-width: 767.98px) {

  .cards {
    margin: 24px 0;
  }

  .cards__list {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 24px;
  }

  .cards__link {
    width: 100%;
    max-height: 240px;
    height: auto;
  }

  .cards__link picture {
    width: 100%;
  }
}
@media (hover: hover) {

  .cards__link:hover .cards__img {
    -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
            transform: scale(1.1);
  }

  .cards__link:hover .cards__name {
    color: var(--pink);
  }
}

.faq {
  margin: 40px 0;
}

.faq__inner {
  border-radius: 16px;
  padding: 24px;
  background-color: #0a0012;
}

.faq__head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 20px;
  margin-bottom: 20px;
}

.faq__title {
  font-weight: 500;
  font-size: 40px;
  line-height: 120%;
  text-align: center;
  color: var(--white);
}

.faq__subtitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  color: #848484;
}


.main-info__item {
  position: relative;
  min-height: 8.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 1.25rem 1.875rem;
  border-radius: 12px;
  background: url("../assets/img/main-bg/01.jpg") center/cover no-repeat;
  color: var(--white);
  transition: all 0.3s ease;
}

.main-info__item:hover {
  background-size: auto 120%;
}

.main-info__item:hover .cards__name {
  color: var(--pink);
}

@media (max-width: 48em) {
  .main-info__item {
    padding: 0.9375rem;
    min-height: 7.5rem;
  }
}

@media (max-width: 48em) {
  .main-info__item {
    padding: 0.9375rem;
    min-height: 7.5rem;
  }
}

.main-info__item--swap {
  background-image: linear-gradient(0deg, rgba(10, 0, 18, 0.7), rgba(10, 0, 18, 0.7) 100%), url("../assets/img/main-bg/01.jpg");
  background-position: 5%;
  background-repeat: no-repeat;
  background-size: auto 105%;
}

.main-info__item--uniswap {
  background-image: linear-gradient(0deg, rgba(10, 0, 18, 0.7), rgba(10, 0, 18, 0.7) 100%), url("../assets/img/main-bg/02.jpg");
  background-position: 5%;
  background-repeat: no-repeat;
  background-size: auto 105%;
}

.main-info__item--rwa {
  background-image: linear-gradient(0deg, rgba(10, 0, 18, 0.7), rgba(10, 0, 18, 0.7) 100%), url("../assets/img/main-bg/03.jpg");
  background-position: 5%;
  background-repeat: no-repeat;
  background-size: auto 105%;
}

.main-info__item--sawa {
  background-image: linear-gradient(0deg, rgba(10, 0, 18, 0.7), rgba(10, 0, 18, 0.7) 100%), url("../assets/img/main-bg/04.jpg");
  background-position: 5%;
  background-repeat: no-repeat;
  background-size: auto 105%;
}
.main-info__item--twitter {
  background-image: url("../assets/img/main-bg/05.jpg");
  background-position: right;
  background-repeat: no-repeat;
  background-size: auto 100%;
}

.accordion {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 20px;
}

.accordion__item {
  overflow: hidden;
}

.accordion__item.active .accordion__content {
  row-gap: 12px;
  padding-top: 20px;
  max-height: 1500px;
}

.accordion__item.active .accordion__header-line img {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
  -webkit-filter: brightness(0) saturate(100%) invert(37%) sepia(94%) saturate(1907%) hue-rotate(307deg) brightness(93%) contrast(91%);
          filter: brightness(0) saturate(100%) invert(37%) sepia(94%) saturate(1907%) hue-rotate(307deg) brightness(93%) contrast(91%);
}

.accordion__header {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-column-gap: 20px;
     -moz-column-gap: 20px;
          column-gap: 20px;
  border-radius: 10px;
  padding: 16px 24px;
  background-color: #363a41;
  cursor: pointer;
}

.accordion__header-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 120%;
  color: var(--white);
}

.accordion__header-line {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 24px;
  height: 24px;
  min-width: 24px;
  -webkit-transition: background-image 0.2s linear;
  -o-transition: background-image 0.2s linear;
  transition: background-image 0.2s linear;
}

.accordion__header-line img {
  -webkit-transition: 0.2s linear;
  -o-transition: 0.2s linear;
  transition: 0.2s linear;
}

.accordion__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.4s linear, padding 0.3s linear;
  -o-transition: max-height 0.4s linear, padding 0.3s linear;
  transition: max-height 0.4s linear, padding 0.3s linear;
}

.accordion__content a {
  font-weight: 600;
  font-size: 14px;
  line-height: 114%;
  text-decoration: underline;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
  color: #848484;
}

.accordion__text {
  padding: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: var(--white);
}
.accordion__text > span {
  font-weight: 700;
  line-height: 2;
}
.accordion__subtitle {
  padding: 10px;
  padding-top: 16px;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: var(--white);
}

.accordion__link-video {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 8px;
     -moz-column-gap: 8px;
          column-gap: 8px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 114%;
  text-decoration: underline;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
  color: #848484;
}

.agreement__text {
  font-weight: 500;
  font-size: 14px;
  color: var(--white);
  direction: rtl;
  padding-bottom: 10px;
}

.agreement__text span {
  font-weight: 700;
}

.agreement__title {
  font-weight: 700;
  font-size: 22px;
  color: var(--white);
  text-align: center;
  padding-bottom: 20px;
}

.agreement__subtitle {
  font-weight: 700;
  font-size: 14px;
  color: var(--white);
}

.footer {
  margin-top: 30px;
  padding: 62px 0 72px;
  border-top: 1px solid rgba(255, 255, 255, 0.16);
}

.footer .container {
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  max-width: 1780px;
}

.footer__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-column-gap: 20px;
     -moz-column-gap: 20px;
          column-gap: 20px;
}

.footer__logo {
  margin-top: 18px;
}

.footer__nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-column-gap: 80px;
     -moz-column-gap: 80px;
          column-gap: 80px;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.footer__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 12px;
}

.footer__link {
  font-size: 14px;
  line-height: 120%;
  color: var(--grey-2);
  cursor: pointer;
}

@media (max-width: 479.98px) {
  .container {
    max-width: none;
  }
}

@media (max-width: 991.98px) {

  .faq {
    margin: 24px 0;
  }

  .faq__inner {
    padding: 20px 16px;
  }

  .faq__title {
    font-size: 24px;
  }

  .faq__subtitle {
    font-size: 18px;
  }

  .accordion {
    row-gap: 16px;
  }

  .cards__img {
    -o-object-fit: cover;
       object-fit: cover;
  }

  .cards__name {
    left: 20px;
    bottom: 20px;
  }

  .footer {
    padding: 40px 0 60px;
    margin-top: 15px;
  }

  .footer__nav {
    -webkit-column-gap: 40px;
       -moz-column-gap: 40px;
            column-gap: 40px;
  }
}
